//@flow

/* eslint-env browser */

import "@2po-c21/components/build/esm/index.css";
import "./src/styles/index.scss";

// import analyticstracker from "analyticstracker";
import "analytics-transQA";
import "analytics-transGTM";

// Google reCaptcha
import moment from "moment";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";

export const wrapRootElement = ({ element }: any) => {
    const gtmParams = { id: process.env.GATSBY_GTM_ID };

    return (
        <GTMProvider state={gtmParams}>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.GATSBY_G_RECAPTCHA_SITE_KEY}
            >
                {element}
            </GoogleReCaptchaProvider>
        </GTMProvider>
    );
};

// Ensure a timestamp just in case
window.__BUILD_TIMESTAMP__ = moment().subtract(1, "hour").toISOString();

(async () => {
    try {
        const response = await fetch("/timestamp");
        const timestamp = await response.text();
        const timestampMoment = moment(
            timestamp.replace("\n", ""),
        ).toISOString();
        // If check because moment returns null if it can't parse toISOString
        // A null value will override the fallback defined above, which we dont want
        if (timestampMoment) {
            window.__BUILD_TIMESTAMP__ = timestampMoment;
        }
    } catch {
        // Timestamp could not be found
        // Or parsed
        // Do nothing, prevent error
    }
})();
